<template>
  <div class="more">
    <div class="more-wrapper" v-for="(i, index) in configData" :key="i.name">
      <div class="more-main">
        <div class="more-word1">{{ i.name }}</div>
        <div class="more-word2">
          {{ i.value }}
          <img
            src="../rubbishSort/img/垃圾分类切图/left.png"
            alt=""
            @click="selectClick(index)"
          />
        </div>
      </div>
      <div class="dr"></div>
    </div>
    <div class="button" @click="confirmSearch">确定</div>
    <div v-for="(i, index) in configData" :key="i.id">
      <v-picker
        :value-show="configData[index].show"
        :columns="configData[index].columns"
        @cancel="configData[index].show = false"
        @clickOverlay="configData[index].show = false"
        @confirmPicker="confirm($event, index)"
      ></v-picker>
    </div>
  </div>
</template>
<script>
import {
  actAuditStsColumns,
  buildingDataColumns,
  communityIdsColumns,
  configData,
  isSignPromiseColumns,
  villageIdColumns,
} from "@/views/rubbishSort/map";

export default {
  data() {
    return {
      configData: configData,
    };
  },
  mounted() {
    //初始化value的值
    configData.forEach((i) => {
      if (i.value !== "") {
        i.value = "";
        i.status = null;
      }
    });
  },
  methods: {
    selectClick(index) {
      this.configData[index].show = !this.configData[index].show;
    },
    confirm(e, index) {
      //使数组里的对象属性值具有响应
      this.$set(this.configData[index], `value`, e);
      this.$set(
        this.configData[index],
        `status`,
        this.configData[index].columns.indexOf(e)
      );
      this.configData[index].show = false;
    },
    confirmSearch() {
      let searchData = {};
      this.configData.forEach((i) => {
        searchData[i.statusName] = i.status;
      });
      this.$store.commit("SET_SEARCHDATA", searchData);
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.more {
  font-family: PingFangSC-Regular, PingFang SC;
  position: relative;
  height: 95vh;
  .more-wrapper {
    .more-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 28px 46px 0 46px;

      .more-word1 {
        font-size: 32px;
        color: #343332;
      }

      .more-word2 {
        display: flex;
        align-items: center;
        font-size: 32px;
        font-weight: 600;
        color: #323334;
      }
    }

    .dr {
      opacity: 0.17;
      border: 1px solid #979797;
      margin: 24px 28px 0 28px;
    }
  }
  .button {
    position: absolute;
    width: 598px;
    height: 80px;
    background: #2e5da2;
    border-radius: 40px;
    text-align: center;
    font-size: 40px;
    color: #ffffff;
    line-height: 76px;
    bottom: 116px;
    left: 76px;
  }
}
</style>
